export class CancelError extends Error {}

export function promiseDebounce<P extends Array<any>, T>(func: (...args: P) => Promise<T>, wait = 100): typeof func {
    let cancel: () => void = () => {};
    let isRunning = false;

    return (...args: P) => {
        if (isRunning) {
            return Promise.reject(new Error('Debounce cancel'));
        }
        cancel();

        return new Promise<T>((resolve, reject) => {
            const timer = setTimeout(() => {
                isRunning = true;
                const result = func(...args);

                resolve(result);
                result.finally(() => {
                    isRunning = false;
                });
            }, wait);

            cancel = () => {
                clearTimeout(timer);
                reject(new CancelError('Debounce cancel'));
            };
        });
    };
}

export function ignoreError<P extends Array<any>, T>(
    func: (...args: P) => Promise<T>,
): (...args: P) => Promise<T | undefined> {
    return (...args: P) => func(...args).catch(() => undefined);
}
