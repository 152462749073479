import { FetchService } from '../fetch/fetch';
import { getPublicEnv } from '../env/env';
import { isObjectWithKey, getBoolean, getNumber } from '../validateData';
import { WardrobePriceRequestParam, WardrobePriceResponse } from '../../types/Project';

const hashPriceRequestParam = (node: WardrobePriceRequestParam) => {
    const string = JSON.stringify(node);
    let hash = 0;

    for (let i = 0; i < string.length; i++) {
        const code = string.charCodeAt(i);

        hash = (hash << 5) - hash + code;
        hash = hash & hash; // Convert to 32bit integer
    }

    return Math.abs(hash).toString(32);
};

export class PriceService {
    private fetch: FetchService;
    private fetchPriceCache: { [key: string]: Promise<WardrobePriceResponse> } = {};

    constructor(fetch: FetchService) {
        this.fetch = fetch;
    }

    async fetchPrice(
        node: WardrobePriceRequestParam,
        currencyCode: string,
        unit: 'MM' | 'DIN',
    ): Promise<WardrobePriceResponse> {
        const hash = `${hashPriceRequestParam(node)}-${currencyCode}-${unit}`;
        const valueFromCache = this.fetchPriceCache[hash];

        if (valueFromCache) {
            return valueFromCache;
        }
        const resultPromise = (async () => {
            const calcProxyUrl = await getPublicEnv('CALC_PROXY_URL');

            const responseData = await this.fetch.request(calcProxyUrl, {
                method: 'POST',
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    'x-currency': currencyCode,
                    'x-unit': unit,
                },
                body: JSON.stringify(node),
            });

            if (!isObjectWithKey(responseData) || typeof responseData.unitPriceWithChildItems !== 'number') {
                throw new Error('Invalid response');
            }

            const result: WardrobePriceResponse = {
                includesTax: getBoolean(responseData.includesTax, false),
                unitPriceWithChildItems: getNumber(responseData.unitPriceWithChildItems, 0),
            };

            return result;
        })();

        this.fetchPriceCache[hash] = resultPromise;

        resultPromise.catch(() => {
            delete this.fetchPriceCache[hash];
        });

        return resultPromise;
    }
}
