import { useLanguage } from './LanguageProvider';

interface TrProps {
    labelKey: string;
    defaultValue: string;
    variables?: { [key: string]: string | number };
}
export const Tr = ({ labelKey, defaultValue, variables }: TrProps) => {
    const value = useTr(labelKey, defaultValue, variables);

    return <span>{value}</span>;
};

export const useTr = (labelKey: string, defaultValue: string, variables?: { [key: string]: string | number }) => {
    const { t } = useLanguage();

    return t(labelKey, defaultValue, variables);
};
