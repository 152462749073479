import { useEffect, useState } from 'react';
import { getPublicEnv, getPublicEnvFromCache } from '../services/env/env';
import { PublicEnv } from '../services/env/domain';

export function useEnv(keyName: keyof PublicEnv) {
    const [value, setValue] = useState<string | null>(getPublicEnvFromCache(keyName));

    useEffect(() => {
        getPublicEnv(keyName).then(setValue);
    }, [keyName]);

    return value;
}
